
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import LeftRightBox from "../../components/LeftRightBox/LeftRightBox";
import MainLayout from "../../layouts/MainLayout";
import "./taste.scss";

const Taste = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("taste:metaTitle")}>
      <>
        <HeroImg customClass="taste-hero" subtitle={t("taste:heroSubtitle")} title={t("taste:heroTitle")} backgroundImage="bg/taste-hero.jpg" />

        <div className="taste-section-two">
          <p>{t("taste:sectionOneText")}</p>
          <p>{t("taste:sectionOneTextTwo")}</p>
        </div>

        <div className="taste-section-three">
          <LeftRightBox customClass="reverse">          
            {<div className="text-wrap">
              <h3>{t("taste:sectionTwoTitle")}</h3>
              <p>{t("taste:sectionTwoText")}</p>
            </div>}
            {<img src="/img/taste-one.jpg" alt="taste-one" />}
          </LeftRightBox>

          <LeftRightBox>
            {<img src="/img/taste-two.jpg" alt="taste-two" />}
            {<div className="text-wrap">
              <h3>{t("taste:sectionThreeTitle")}</h3>
              <p>{t("taste:sectionThreeText")}</p>
            </div>} 
          </LeftRightBox>

          <LeftRightBox customClass="reverse">
            {<div className="text-wrap">
              <h3>{t("taste:sectionFourTitle")}</h3>
              <p>{t("taste:sectionFourText")}</p>
            </div>}
            {<img src="/img/taste-three.jpg" alt="taste-three" />}
          </LeftRightBox>

          <LeftRightBox>
            {<img src="/img/taste-four.jpg" alt="taste-four" />}
            {<div className="text-wrap">
              <h3>{t("taste:sectionFiveTitle")}</h3>
              <p>{t("taste:sectionFiveText")}</p>
            </div>}
          </LeftRightBox>

          <LeftRightBox customClass="reverse">  
            {<div className="text-wrap">
              <h3>{t("taste:sectionSixTitle")}</h3>
              <p>{t("taste:sectionSixText")}</p>
            </div>}
            {<img src="/img/taste-five.jpg" alt="taste-five" />}
          </LeftRightBox>
        </div>
      </>
    </MainLayout>
  )
}

export default Taste;