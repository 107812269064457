import React from 'react';
import './left-right-box.scss';

const LeftRightBox = ({ children, customClass }) => {
  return (
    <div className={`left-right-box ${customClass}`}>
    <div className="left-box">
      {children[0]}
    </div>
    <div className="right-box">
      {children[1]}
    </div>
  </div>
  )
}

export default LeftRightBox;